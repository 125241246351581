import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const WishListIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      height="512px"
      width="512px"
      id="Layer_1"
      enableBackground="new 0 0 32 32"
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M26.1923828,21.6762695H8.0234375l3.8295898-19.4833984   C11.8659668,2.1270752,11.8521118,2.0645752,11.8519897,2H9.8522339H2.5c-0.5522461,0-1,0.4477539-1,1s0.4477539,1,1,1h6.9591064   L5.8261719,22.4833984c-0.0576172,0.293457,0.019043,0.597168,0.2089844,0.828125s0.4736328,0.3647461,0.7724609,0.3647461   h19.3847656c0.5522461,0,1-0.4477539,1-1S26.7446289,21.6762695,26.1923828,21.6762695z" />
        <circle cx="19.6256008" cy="28" r="2" />
        <circle cx="12.2049274" cy="28" r="2" />
        <path d="M10.871582,2L8.119873,16h15.4137573L30.5,2H10.871582z M20.7764893,8.9428101L17.6256104,12.09375   l-3.1508789-3.1509399c-0.6947021-0.6946411-0.6947021-1.8209229,0-2.515564c0.6946411-0.6947021,1.8209229-0.6946411,2.515625,0   l0.6352539,0.6353149l0.6352539-0.6353149c0.6947021-0.6946411,1.8209839-0.6947021,2.515625,0   C21.4711304,7.1218872,21.4711304,8.2481689,20.7764893,8.9428101z" />
      </g>
    </SvgIcon>
  );
};
